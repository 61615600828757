import { useState } from "react";

export type ChatType = {
  id: string,
  name: string,
  category: string,
  initialMessage: string
}

export const chatTypes: ChatType[] = [
  {
    id: "default",
    name: "MentechAI",
    category: "Default",
    initialMessage: "Hej! Jeg har dybdegående kendskab til Mentech og kan hjælpe dig med det meste 🦾🤖 Hvordan kan jeg hjælpe dig i dag?"
  },
  {
    id: "marketing_generator",
    name: "Generator",
    category: "Marketing",
    initialMessage: "Hej! Jeg kan hjælpe dig med nemt at generere en tekst til brug i marketing eller SoMe opslag 😎 Hvilken tekst skal vi generere nu? 🙌"
  },
  {
    id: "marketing_translator",
    name: "Translator",
    category: "Marketing",
    initialMessage: "Hej! Jeg kan hjælpe dig med nemt at oversætte en tekst til de sprog som Mentech benytter 😎 Hvilken tekst skal vi oversætte nu? 🙌"
  },
  {
    id: "kristian_mensing",
    name: "Kristian Mensing",
    category: "Personal",
    initialMessage: "Hej! Jeg kan hjælpe dig med at skrive som Kristian Mensing og det er bare ud over stepperne! 😎 Hvad skal vi skrive om? 🙌"
  },
  {
    id: "charlotte_mensing",
    name: "Charlotte Mensing",
    category: "Personal",
    initialMessage: "Hej! Jeg kan hjælpe dig med at skrive som Charlotte Mensing! 😎 Hvad skal vi skrive om? 🙌"
  },
  {
    id: "support",
    name: "Customer Support",
    category: "Support",
    initialMessage: "Hej! Jeg er Roberta, kundeservicerobotten hos Mentech 🤖 Hvordan kan jeg hjælpe dig i dag?"
  },
  {
    id: "support_insurance",
    name: "Insurance Support",
    category: "Support",
    initialMessage: "Hej! Jeg er Roberta, Insurance AI hos Mentech 🤖 Hvordan kan jeg hjælpe dig i dag?"
  },
  {
    id: "support_intent",
    name: "Support Intent",
    category: "Support",
    initialMessage: "Hej! Jeg er Roberta, jeg hjælper dig med at klassificere en henvendelse fra en kunde, ud fra prædefinerede kategorier. Send mig en henvendelse fra en kunde, og jeg klassificere den for dig! 🤖"
  },
  {
    id: "development_query",
    name: "Query maker",
    category: "Development",
    initialMessage: "Hej! Jeg er Roberta, jeg hjælper dig med at lave MySQL queries for at hente data fra vores database 🤖"
  },
];

type Props = {
  onChange: (chatType: ChatType) => void
}

export const ChatTypeSelect: React.FC<Props> = ({onChange}) => {
  const [chatType, setChatType] = useState<ChatType>(chatTypes[0]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const chatType = chatTypes.find(chatType => chatType.id === event.target.value) || chatTypes[0];
    setChatType(chatType);
    onChange(chatType);
  };

  const groupedChatTypes = chatTypes.reduce((groups, chatType) => {
    if (!groups[chatType.category]) {
      groups[chatType.category] = [];
    }

    groups[chatType.category].push(chatType);

    return groups;
  }, {} as { [key: string]: ChatType[] });

  return (
    <select value={chatType.id} onChange={handleChange} className="form-control">
      {Object.entries(groupedChatTypes).map(([groupName, groupChatTypes]) => (
        <optgroup label={groupName} key={groupName}>
          {groupChatTypes.map(chatType => (
            <option value={chatType.id} key={chatType.id}>
              {chatType.name}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
};