/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/chat/mentech-ai'
        title={intl.formatMessage({id: 'MENU.CHAT'})}
        fontIcon='bi-chat-left'
        icon='message-text-2'
      />
      <SidebarMenuItem
        to='/apps/support'
        title='Support'
        icon='question-2'
        fontIcon='bi-question-circle'
      />
    </>
  )
}

export {SidebarMenuMain}
